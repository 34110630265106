/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

/**
* Template Name: Company - v4.7.0
* Template URL: https://bootstrapmade.com/company-free-html-bootstrap-template/
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/

@import url(http://fonts.googleapis.com/css?family=Lato:400,300,300italic,400italic,700,700italic,900);
* {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

body {
  font-family: "Open Sans", sans-serif;
  font-family: 'Roboto','Noto Sans JP', sans-serif;
  background: #f1f3f2;
  width:100%;
  overflow-x: hidden;
}

html {
  scroll-behavior: smooth;
}

/* PAGE SCROLLBAR STYLE START*/
/* width */
::-webkit-scrollbar {
  width: 0.5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #cfcfcf; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
/* PAGE SCROLLBAR STYLE END*/

a {
  color: #603472;
  text-decoration: none;
}

a:hover {
  /* color: #4a30ed; */
  color:#603472;
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Roboto", sans-serif;
}

/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  visibility: visible;
  opacity: 0.8;
  right: 15px;
  bottom: 15px;
  z-index: 996;
  background: #413539;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}
.back-to-top i {
  font-size: 28px;
  color: #fff;
  line-height: 0;
}
.back-to-top:hover {
  background: #413539;
  opacity: 0.7;
  color: #fff;
}
.back-to-top.active {
  visibility: visible;
  opacity: 1;
}

/*--------------------------------------------------------------
# Disable AOS delay on mobile
--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  [data-aos-delay] {
    -webkit-transition-delay: 0 !important;
         -o-transition-delay: 0 !important;
            transition-delay: 0 !important;
  }
}
/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
  background: #f1f3f2;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  z-index: 997;
  -webkit-box-shadow: 0px 5px 15px rgb(79 28 70 / 94%);
          box-shadow: 0px 5px 15px rgb(79 28 70 / 94%);
}

/* #header-top #navbar .collapse ul a:hover {
  text-decoration: none;
  color: #ffc107;
} */

/* #header-top #navbar .collapse.ul.li.a.active-underline-animation.nav-link:active{
  text-decoration:underline;
} */

.container-fluid .logo {
  font-size: 35px;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-weight: 700;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #603472;
}

.navbar-toggler{
  border: 1px solid #cfcfcf;
}

.navbar-toggler:focus{
  -webkit-box-shadow: #cfcfcf !important;
          box-shadow: #cfcfcf !important;
}

.nav-item.active {
  color:#000000;
}

#header .logo a { 
  color: #111;
} 

#header .logo a span {
  color: #603472;
  font-family: 'Roboto', sans-serif;
  letter-spacing: 2px;

}
#header .logo img {
  max-height: 40px;
}

/*--------------------------------------------------------------
# Header Social Links
--------------------------------------------------------------*/
.header-social-links {
  margin-left: 20px;
  border-left: 1px solid #c4c4c4;
}
.header-social-links a {
  color: #a0a0a0;
  display: inline-block;
  line-height: 0px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  padding-left: 20px;
}
.header-social-links a i {
  line-height: 0;
}
.header-social-links a:hover {
  color: #4a30ed;
}
#header #navbar->.navbar-collapse->ul.a:hover { 
  background: yellow; 
}  /* ホバー時 */

#header #navbar->.navbar-collapse->ul.a:active { 
  color: red; 
}   

@media (max-width: 768px) {
  .header-social-links {
    padding: 0 15px 0 0;
    border-left: 0;
  }
/* 企業理念 Style START*/
  .kigyorinenDv{
    /* display: block !important; */
    padding-right: 10px !important;
  }
  .kigyorinenDv-cover {
    display: block !important;
    padding:20px !important;
  }
.kigyorinenPStyle{
  margin:20px 0 auto;  
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  line-height: 24px;
  letter-spacing: 1px;
  word-wrap: break-word;
  padding-right: 20px;
}

#appRinenBody{
  margin: 5px 0 0 23px;
  font-family: 'Roboto', sans-serif;
}
/* 企業理念 Style  END*/
  .ceoMsgDv{
    /* display: block !important; */
    padding-right: 10px !important;
  }
  .ceoMsgDv-cover {
    display: block !important;
    padding:20px !important;
  }
  
  .ceoMsg {
    width: 100% !important;
    word-break: break-all !important;
    padding-top: 0px !important;
  }
  .ceoMsgImage {
    width: 100% !important;
    pointer-events: none;
  }
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/**
* Desktop Navigation 
*/
.navbar {
  padding: 0 !important;
} 
.navbar ul {
  margin: 0;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  list-style: none;
  /* align-items: center; */
}
.navbar li {
  position: relative;
}
.navbar a, .navbar a:focus {
  /* display: flex;
  align-items: center; */
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0 0 0 30px;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: 600;
  color: #111;
  white-space: nowrap;
  text-transform: uppercase;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.navbar a i, .navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}
.navbar a:hover, .navbar .active, .navbar .active:focus, .navbar li:hover > a {
   color: #5d2d8d;
}
/* .navbar a:hover, .navbar .active, .navbar .active:focus, .navbar li:active{
  color: #121113;
} */
.navbar-expand-lg .navbar-nav .nav-link::after{
  color: #121113;
}
.navbar .dropdown ul {
  display: block;
  position: absolute;
  left: 14px;
  top: calc(100% + 30px);
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  -webkit-box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
          box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  border-top: 2px solid #4a30ed;
}
.navbar .dropdown ul li {
  max-width: 300px;
  min-width: 200px;
}
.navbar .dropdown ul a {
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 500;
  text-transform: none;
  color: #111;
}
.navbar .dropdown ul a i {
  font-size: 12px;
}
.navbar .dropdown ul a:hover, .navbar .dropdown ul .active:hover, .navbar .dropdown ul li:hover > a {
  color: #4a30ed;
}
.navbar .dropdown:hover > ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}
.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}
.navbar .dropdown .dropdown:hover > ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}
@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }
  .navbar .dropdown .dropdown:hover > ul {
    left: -100%;
  }
} 

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
.icon-boxiconbox-blue{
  -webkit-box-shadow: 2px 4px 12px rgb(26 23 23 / 90%);
          box-shadow: 2px 4px 12px rgb(26 23 23 / 90%);
  -webkit-transition: all .3s cubic-bezier(0,0,.5,1);
  -o-transition: all .3s cubic-bezier(0,0,.5,1);
  transition: all .3s cubic-bezier(0,0,.5,1);
  border-radius: 7px;
  margin-bottom:20px;
}

.icon-box.iconbox-blue:hover {
  -webkit-box-shadow: 2px 4px 16px rgba(0,0,0,.16) !important;
          box-shadow: 2px 4px 16px rgba(0,0,0,.16) !important;
  -webkit-transform: scale3d(1.01,1.01,1.01) !important;
          transform: scale3d(1.01,1.01,1.01) !important;
}

video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}

.overlay {
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.overlay a {
    /* background: #008135 none repeat scroll 0 0; */
    width:170px;
    height: 140px;
    margin: 2rem 3rem 0;
    mix-blend-mode: overlay;
    padding: 5px 15px;
    text-align: center;
    padding-bottom:30px;
    /* background:#5f666c; */
}

.overlay a i{
  font-size: 100px;
  font-weight: bolder;
  text-align: center;
  opacity: 0.2;
}

/* #myBtn {
  width: 200px;
  font-size: 18px;
  padding: 10px;
  position:absolute;
  border: none;
  background: #000;
  color: #fff;
  cursor: pointer;
}

#myBtn:hover {
  background: #ddd;
  color: black;
} */

#hero {
  width: 100%;
  height: 58vh;
  background-color: rgba(247,247,247,1);
  overflow: hidden;
  position: relative;
}

#hero .carousel, #hero .carousel-inner, #hero .carousel-item, #hero .carousel-item::before {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}
#hero .carousel-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: absolute;
  bottom: 60px;
  top: 70px;
  left: 55px;
  right: 55px;
}
#hero .carousel-content {
  background: rgba(4, 4, 4, 0.7);
  padding: 20px;
  color: #fff;
  border-top: 5px solid #4a30ed;
}
#hero .carousel-content h2 {
  color: #fff;
  margin-bottom: 20px;
  font-family: "Roboto", sans-serif;
  font-size: 30px;
  font-weight: 200;
  letter-spacing: 2px; 
  text-transform: uppercase;
}

#hero .btn-get-started {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 32px;
  border-radius: 4px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  line-height: 1;
  margin: 10px;
  color: #fff;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
  border: 2px solid #4a30ed;
}
#hero .btn-get-started:hover {
  background: #4a30ed;
  color: #fff;
  text-decoration: none;
}
#hero .carousel-inner .carousel-item {
  -webkit-transition-property: opacity;
  -o-transition-property: opacity;
  transition-property: opacity;
  background-position: center top;
}
#hero .carousel-inner .carousel-item,
#hero .carousel-inner .active.carousel-item-start,
#hero .carousel-inner .active.carousel-item-end {
  opacity: 0;
}
#hero .carousel-inner .active,
#hero .carousel-inner .carousel-item-next.carousel-item-start,
#hero .carousel-inner .carousel-item-prev.carousel-item-end {
  opacity: 1;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}
#hero .carousel-inner .carousel-item-next,
#hero .carousel-inner .carousel-item-prev,
#hero .carousel-inner .active.carousel-item-start,
#hero .carousel-inner .active.carousel-item-end {
  left: 0;
  /* //transform: translate3d(0, 0, 0); */
}
#hero .carousel-control-next-icon, #hero .carousel-control-prev-icon {
  background: none;
  font-size: 30px;
  line-height: 0;
  width: auto;
  height: auto;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 50px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  color: rgba(255, 255, 255, 0.6);
  width: 54px;
  height: 54px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
#hero .carousel-control-next-icon:hover, #hero .carousel-control-prev-icon:hover {
  background: rgba(255, 255, 255, 0.6);
  color: rgba(255, 255, 255, 0.8);
}
#hero .carousel-indicators li {
  cursor: pointer;
  background: #fff;
  overflow: hidden;
  border: 0;
  width: 12px;
  height: 12px;
  border-radius: 50px;
  opacity: 0.6;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
#hero .carousel-indicators li.active {
  opacity: 1;
  background: #4a30ed;
}
@media (min-width: 1024px) {
  #hero .carousel-content {
    width: 60%;
  }
  #hero .carousel-control-prev, #hero .carousel-control-next {
    width: 5%;
  }
}
@media (max-width: 992px) {
  #hero .carousel-container {
    top: 58px;
  }
  #hero .carousel-content h2 {
    margin-bottom: 15px;
    font-size: 22px;
  }
  #hero .carousel-content p {
    font-size: 15px;
  }
}
@media (max-height: 500px) {
  #hero {
    height: 120vh;
  }
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  overflow: hidden;
}

.section-bg {
  background-color: #f7f7f7;
}

.section-title {
  text-align: center;
  padding-bottom: 30px!important;
  padding-top:20px;
  margin-top:10px;
}
.section-title h2 {
  font-size: 32px;
  font-weight: bold;  
  top:25px;
  text-transform: uppercase;
  margin-bottom: 30px; 
  padding-bottom: 20px;
  position: relative;
}
.section-title h2::after {
  content: "";
  position: absolute;
  display: block;
  width: 50px;
  height: 3px;
  background: #3281b2;
  bottom: 0;
  left: calc(50% - 25px);
}
.section-title p {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Breadcrumbs
--------------------------------------------------------------*/
.breadcrumbs {
  padding: 15px 0;
  background: -o-linear-gradient(334deg,#00a0e9, #9d4140) !important;
  background: linear-gradient(334deg,#00a0e9, #9d4140) !important;
  min-height: 40px;
  margin-top: 78px;
}
.breadcrumbs h2 {
  font-size: 21px;
  font-weight: 400;
  color: #fff;
  padding-top: 18px;
}
.breadcrumbs ol {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
  color: #fff;
  font-family: "Roboto", sans-serif;
}
.breadcrumbs ol a {
  color: #fff;
}
.breadcrumbs ol li + li {
  padding-left: 10px;  
}
.breadcrumbs ol li + li::before {
  display: inline-block;
  padding-right: 10px;
  color: #fff;
  content: "/";
}
@media (max-width: 991px) {
  .breadcrumbs {
    margin-top: 58px;
  }
  .breadcrumbs .d-flex {
    display: block !important;
  }
  .breadcrumbs ol {
    display: block;
  }
  .breadcrumbs ol li {
    display: inline-block;
  }
}

/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/
.services .icon-box {
  text-align: center;
  padding: 70px 20px 80px 20px;
  -webkit-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
  background: #fff;  
  width: 100%;
  border-radius: 7px;
  -webkit-box-shadow: 2px 4px 12px rgba(0,0,0,.08);
          box-shadow: 2px 4px 12px rgba(0,0,0,.08);
  -webkit-transition: all .3s cubic-bezier(0,0,.5,1);
  -o-transition: all .3s cubic-bezier(0,0,.5,1);
  transition: all .3s cubic-bezier(0,0,.5,1);
  margin-bottom:20px;
}

.services .icon-box .icon {
  margin: 0 auto;
  width: 100px;
  height: 100px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-transition: ease-in-out 0.3s;
  -o-transition: ease-in-out 0.3s;
  transition: ease-in-out 0.3s;
  position: relative;
}
.services .icon-box .icon i {
  font-size: 36px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  position: relative;
}
.services .icon-box .icon svg {
  position: absolute;
  top: 0;
  left: 0;
}
.services .icon-box .icon svg path {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  fill: #f5f5f5;
}
.services .icon-box h4 {
  font-weight: 600;
  margin: 10px 0 15px 0;
  font-size: 22px;
}
.services .icon-box h4 a {
  color: #111;
  -webkit-transition: ease-in-out 0.3s;
  -o-transition: ease-in-out 0.3s;
  transition: ease-in-out 0.3s;
}
.services .icon-box p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}
.services .icon-box:hover {
  border-color: #fff;
  /* box-shadow: 0px 0 35px 0 rgba(0, 0, 0, 0.08); */
  -webkit-box-shadow: 2px 4px 16px rgba(0,0,0,.16);
          box-shadow: 2px 4px 16px rgba(0,0,0,.16);
  -webkit-transform: scale3d(1.01,1.01,1.01);
          transform: scale3d(1.01,1.01,1.01)
}
.services .iconbox-blue i {
  color: #47aeff;
}
.services .iconbox-blue:hover .icon i {
  color: #fff;
}
.services .iconbox-blue:hover .icon path {
  fill: #47aeff;
}
.services .iconbox-orange i {
  color: #ffa76e;
}
.services .iconbox-orange:hover .icon i {
  color: #fff;
}
.services .iconbox-orange:hover .icon path {
  fill: #ffa76e;
}
.services .iconbox-pink i {
  color: #e80368;
}
.services .iconbox-pink:hover .icon i {
  color: #fff;
}
.services .iconbox-pink:hover .icon path {
  fill: #e80368;
}
.services .iconbox-yellow i {
  color: #ffbb2c;
}
.services .iconbox-yellow:hover .icon i {
  color: #fff;
}
.services .iconbox-yellow:hover .icon path {
  fill: #ffbb2c;
}
.services .iconbox-red i {
  color: #ff5828;
}
.services .iconbox-red:hover .icon i {
  color: #fff;
}
.services .iconbox-red:hover .icon path {
  fill: #ff5828;
}
.services .iconbox-teal i {
  color: #11dbcf;
}
.services .iconbox-teal:hover .icon i {
  color: #fff;
}
.services .iconbox-teal:hover .icon path {
  fill: #11dbcf;
}
.serviceEntry{
  /* width:92%;
  margin-left:65px; */
  width: 99%!important;
  margin: 0 auto!important;
  display: block;
}
/*--------------------------------------------------------------
# Portfolio
--------------------------------------------------------------*/
.portfolio #portfolio-flters {
  padding: 0;
  margin: 0 auto 50px auto;
  list-style: none;
  text-align: center;
}
.portfolio #portfolio-flters li {
  cursor: pointer;
  display: inline-block;
  padding: 8px 20px 10px 20px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  text-transform: uppercase;
  color: #4d4643;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  border-radius: 4px;
}
.portfolio #portfolio-flters li:hover, .portfolio #portfolio-flters li.filter-active {
  color: #fff;
  background: #4a30ed;
}
.portfolio #portfolio-flters li:last-child {
  margin-right: 0;
}
.portfolio .portfolio-item {
  margin-bottom: 30px;
}
.portfolio .portfolio-item .portfolio-info {
  opacity: 0;
  position: absolute;
  left: 30px;
  right: 30px;
  bottom: 0;
  z-index: 3;
  -webkit-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
  background: rgba(255, 255, 255, 0.9);
  padding: 15px;
}
.portfolio .portfolio-item .portfolio-info h4 {
  font-size: 18px;
  color: #fff;
  font-weight: 600;
  color: #111;
}
.portfolio .portfolio-item .portfolio-info p {
  color: #444444;
  font-size: 14px;
  margin-bottom: 0;
}
.portfolio .portfolio-item .portfolio-info .preview-link, .portfolio .portfolio-item .portfolio-info .details-link {
  position: absolute;
  right: 40px;
  font-size: 24px;
  top: calc(50% - 18px);
  color: #2b2b2b;
}
.portfolio .portfolio-item .portfolio-info .preview-link:hover, .portfolio .portfolio-item .portfolio-info .details-link:hover {
  color: #4a30ed;
}
.portfolio .portfolio-item .portfolio-info .details-link {
  right: 10px;
}
.portfolio .portfolio-item .portfolio-links {
  opacity: 0;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 3;
  position: absolute;
  -webkit-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
}
.portfolio .portfolio-item .portfolio-links a {
  color: #fff;
  margin: 0 2px;
  font-size: 28px;
  display: inline-block;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.portfolio .portfolio-item .portfolio-links a:hover {
  color: #57e76f;
}
.portfolio .portfolio-item:hover .portfolio-info {
  opacity: 1;
  bottom: 20px;
}

/*--------------------------------------------------------------
# Portfolio Details
--------------------------------------------------------------*/
.portfolio-details {
  padding-top: 40px;
}
.portfolio-details .portfolio-details-slider img {
  width: 100%;
}
.portfolio-details .portfolio-details-slider .swiper-pagination {
  margin-top: 20px;
  position: relative;
}
.portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
  border: 1px solid #4a30ed;
}
.portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #4a30ed;
}
.portfolio-details .portfolio-info {
  padding: 30px;
  -webkit-box-shadow: 0px 0 30px rgba(17, 17, 17, 0.08);
          box-shadow: 0px 0 30px rgba(17, 17, 17, 0.08);
}
.portfolio-details .portfolio-info h3 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
}
.portfolio-details .portfolio-info ul {
  list-style: none;
  padding: 0;
  font-size: 15px;
}
.portfolio-details .portfolio-info ul li + li {
  margin-top: 10px;
}
.portfolio-details .portfolio-description {
  padding-top: 30px;
}
.portfolio-details .portfolio-description h2 {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 20px;
}
.portfolio-details .portfolio-description p {
  padding: 0;
}

/*--------------------------------------------------------------
# Clients
--------------------------------------------------------------*/
.clients .clients-wrap {
  border-top: 1px solid #ececec;
  border-left: 1px solid #ececec;
}
.clients .client-logo {
  padding: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-right: 1px solid #ececec;
  border-bottom: 1px solid #ececec;
  overflow: hidden;
  background: #fff;
  height: 100px;
}
.clients .client-logo img {
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  height: 36px;
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
}
.clients .client-logo:hover img {
  -webkit-filter: none;
          filter: none;
  -webkit-transform: scale(1.1);
      -ms-transform: scale(1.1);
          transform: scale(1.1);
}

/*--------------------------------------------------------------
# About Us
--------------------------------------------------------------*/
.about-us .content h2 {
  font-weight: 700;
  font-size: 42px;
  line-height: 60px;
  margin-bottom: 20px;
  text-transform: uppercase;
}
.about-us .content h3 {
  font-weight: 500;
  line-height: 32px;
  font-size: 24px;
}
.about-us .content ul {
  list-style: none;
  padding: 0;
}
.about-us .content ul li {
  padding: 10px 0 0 28px;
  position: relative;
}
.about-us .content ul i {
  left: 0;
  top: 7px;
  position: absolute;
  font-size: 20px;
  color: #3a32ee;
}
.about-us .content p:last-child {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Our Team
--------------------------------------------------------------*/
.team .member {
  margin-bottom: 20px;
  overflow: hidden;
  text-align: center;
  border-radius: 5px;
  background: #fff;
  -webkit-box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}
.team .member .member-img {
  position: relative;
  overflow: hidden;
}
.team .member .social {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: 40px;
  opacity: 0;
  -webkit-transition: ease-in-out 0.3s;
  -o-transition: ease-in-out 0.3s;
  transition: ease-in-out 0.3s;
  text-align: center;
  background: rgba(255, 255, 255, 0.85);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.team .member .social a {
  -webkit-transition: color 0.3s;
  -o-transition: color 0.3s;
  transition: color 0.3s;
  color: #111;
  margin: 0 10px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  line-height: 0;
}
.team .member .social a i {
  line-height: 0;
}
.team .member .social a:hover {
  color: #4a30ed;
}
.team .member .social i {
  font-size: 18px;
  margin: 0 2px;
}
.team .member .member-info {
  padding: 25px 15px;
}
.team .member .member-info h4 {
  font-weight: 700;
  margin-bottom: 5px;
  font-size: 18px;
  color: #111;
}
.team .member .member-info span {
  display: block;
  font-size: 13px;
  font-weight: 400;
  color: #b4aca8;
}
.team .member .member-info p {
  font-style: italic;
  font-size: 14px;
  line-height: 26px;
  color: #847872;
}
.team .member:hover .social {
  opacity: 1;
}

/*--------------------------------------------------------------
# Our Skills
--------------------------------------------------------------*/
.skills .progress {
  height: 60px;
  display: block;
  background: none;
  border-radius: 0;
}
.skills .progress .skill {
  padding: 0;
  margin: 0 0 6px 0;
  text-transform: uppercase;
  display: block;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: #111;
}
.skills .progress .skill .val {
  float: right;
  font-style: normal;
}
.skills .progress-bar-wrap {
  background: #e7e7e7;
}
.skills .progress-bar {
  width: 1px;
  height: 10px;
  -webkit-transition: 0.9s;
  -o-transition: 0.9s;
  transition: 0.9s;
  background-color: #4a30ed;
}

/*--------------------------------------------------------------
# Features
--------------------------------------------------------------*/
.features .icon-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 20px;
  background: #fff;
  -webkit-box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  -webkit-transition: ease-in-out 0.3s;
  -o-transition: ease-in-out 0.3s;
  transition: ease-in-out 0.3s;
}
.features .icon-box i {
  font-size: 32px;
  padding-right: 10px;
  line-height: 1;
}
.features .icon-box h3 {
  font-weight: 700;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-size: 16px;
}
.features .icon-box h3 a {
  color: #111;
  -webkit-transition: ease-in-out 0.3s;
  -o-transition: ease-in-out 0.3s;
  transition: ease-in-out 0.3s;
}
.features .icon-box h3 a:hover {
  color: #4a30ed;
}

/*--------------------------------------------------------------
# Pricing
--------------------------------------------------------------*/
.pricing .box {
  padding: 20px;
  background: #fff;
  text-align: center;
  -webkit-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
          box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  position: relative;
  overflow: hidden;
}
.pricing h3 {
  font-weight: 400;
  margin: -20px -20px 20px -20px;
  padding: 20px 15px;
  font-size: 16px;
  font-weight: 600;
  color: #847872;
  background: #f8f8f8;
}
.pricing h4 {
  font-size: 36px;
  color: #4a30ed;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  margin-bottom: 20px;
}
.pricing h4 sup {
  font-size: 20px;
  top: -15px;
  left: -3px;
}
.pricing h4 span {
  color: #bababa;
  font-size: 16px;
  font-weight: 300;
}
.pricing ul {
  padding: 0;
  list-style: none;
  color: #4d4643;
  text-align: center;
  line-height: 20px;
  font-size: 14px;
}
.pricing ul li {
  padding-bottom: 16px;
}
.pricing ul i {
  color: #4a30ed;
  font-size: 18px;
  padding-right: 4px;
}
.pricing ul .na {
  color: #ccc;
  text-decoration: line-through;
}
.pricing .btn-wrap {
  margin: 20px -20px -20px -20px;
  padding: 20px 15px;
  background: #f8f8f8;
  text-align: center;
}
.pricing .btn-buy {
  background: #4a30ed;
  display: inline-block;
  padding: 8px 35px 10px 35px;
  border-radius: 4px;
  color: #fff;
  -webkit-transition: none;
  -o-transition: none;
  transition: none;
  font-size: 14px;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.pricing .btn-buy:hover {
  background: #2ae149;
}
.pricing .featured h3 {
  color: #fff;
  background: #4a30ed;
}
.pricing .advanced {
  width: 200px;
  position: absolute;
  top: 18px;
  right: -68px;
  -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
          transform: rotate(45deg);
  z-index: 1;
  font-size: 14px;
  padding: 1px 0 3px 0;
  background: #4a30ed;
  color: #fff;
}

/*--------------------------------------------------------------
# Frequently Asked Questions
--------------------------------------------------------------*/
.faq .faq-list {
  padding: 0 100px;
}
.faq .faq-list ul {
  padding: 0;
  list-style: none;
}
.faq .faq-list li + li {
  margin-top: 15px;
}
.faq .faq-list li {
  padding: 20px;
  background: #fff;
  border-radius: 4px;
  position: relative;
}
.faq .faq-list a {
  display: block;
  position: relative;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  padding: 0 30px;
  outline: none;
  cursor: pointer;
}
.faq .faq-list .icon-help {
  font-size: 24px;
  position: absolute;
  right: 0;
  left: 20px;
  color: lightgray;
}
.faq .faq-list .icon-show, .faq .faq-list .icon-close {
  font-size: 24px;
  position: absolute;
  right: 0;
  top: 0;
}
.faq .faq-list p {
  margin-bottom: 0;
  padding: 10px 0 0 0;
}
.faq .faq-list .icon-show {
  display: none;
}
.faq .faq-list a.collapsed {
  color: #343a40;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.faq .faq-list a.collapsed:hover {
  color: #4a30ed;
}
.faq .faq-list a.collapsed .icon-show {
  display: inline-block;
}
.faq .faq-list a.collapsed .icon-close {
  display: none;
}
@media (max-width: 1200px) {
  .faq .faq-list {
    padding: 0;
  }
}

/*--------------------------------------------------------------
# Testimonials
--------------------------------------------------------------*/
.testimonials .testimonials-carousel, .testimonials .testimonials-slider {
  overflow: hidden;
}
.testimonials .testimonial-item {
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  padding: 40px;
  -webkit-box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
          box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
  position: relative;
  background: #fff;
}
.testimonials .testimonial-item .testimonial-img {
  width: 90px;
  border-radius: 50px;
  border: 6px solid #fff;
  float: left;
  margin: 0 10px 0 0;
}
.testimonials .testimonial-item h3 {
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0 5px 0;
  color: #111;
}
.testimonials .testimonial-item h4 {
  font-size: 14px;
  color: #999;
  margin: 0;
}
.testimonials .testimonial-item .quote-icon-left, .testimonials .testimonial-item .quote-icon-right {
  color: #dddddd;
  font-size: 26px;
}
.testimonials .testimonial-item .quote-icon-left {
  display: inline-block;
  left: -5px;
  position: relative;
}
.testimonials .testimonial-item .quote-icon-right {
  display: inline-block;
  right: -5px;
  position: relative;
  top: 10px;
}
.testimonials .testimonial-item p {
  font-style: italic;
  margin: 15px 0 0 0;
  padding: 0;
}

/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.w-full{
  width: 100%;
}
.add-width {
  width: 98%;
  margin: 0 auto;
}

.contact{
  padding: 30px 0 30px 0;
}

.contact-container{
  width: 84.5%;
  margin-left:132px;
}

.map-design{
  -webkit-box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.12);
          box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.12);
}

.contact .info-wrap {
  -webkit-box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  padding: 30px;
}
.contact .info {
  background: #fff;
}
.contact .info i {
  font-size: 20px;
  color: #4a30ed;
  float: left;
  width: 44px;
  height: 44px;
  border: 1px solid #4a30ed;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 50px;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.contact-title {
  font-size: 21px;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  margin: 0 0 20px 0;
}

.contact .info h4 {
  padding: 0 0 0 60px;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #111;
}
.contact .info p {
  padding: 0 0 0 60px;
  margin-bottom: 0;
  font-size: 14px;
  color: #444444;
}
.contact .info:hover i {
  background: #4a30ed;
  color: #fff;
}
.contact .php-email-form {
  width: 100%;
  -webkit-box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.12);
          box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.12);
  padding: 30px;
  /* background: #fff; */
}
.entry_form{
  margin: auto;
}
.contact .php-email-form .form-group {
  padding-bottom: 20px;
}
.contact .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}
.contact .php-email-form .error-message br + br {
  margin-top: 25px;
}
.contact .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #6927e8;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}
.contact .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}
.contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #6927e8;
  border-top-color: #eee;
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}
.contact .php-email-form input, .contact .php-email-form textarea {
  border-radius: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
  font-size: 14px;
  border-radius: 4px;
}
.contact .php-email-form input:focus, .contact .php-email-form textarea:focus {
  border-color: #3D68FF;
}

.contact .php-email-form textarea {
  padding: 10px 12px;
  height: 102px;
}

.floating-label{
  padding: 20px !important;
  font-size: 14px;
}

.contact .php-email-form button[type=submit] {
  background: #3281b2;
  border: 0;
  padding: 10px 24px;
  color: #fff;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  border-radius: 4px;
}
.contact .php-email-form button[type=submit]:hover {
  background: #3281b2;
  opacity: 0.7;
}
@-webkit-keyframes animate-loading {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes animate-loading {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Blog
--------------------------------------------------------------*/
.blog {
  padding: 40px 0 20px 0;
}

.entry-boxshadow{
  -webkit-box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.12);
          box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.12);
  padding: 30px;
}

.blog .entry {
  padding: 30px;
  margin-bottom: 60px;
  -webkit-box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
          box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}
.blog .entry .entry-img {
  /* max-height: 440px; */
  /* margin: -30px -30px 20px -30px; */
  overflow: hidden;
  /* width: 80%; */
  width: 40%;
  margin: 0 auto;
  box-shadow: 2px 4px 12px rgb(110 116 114 / 94%);
}
.blog .entry .entry-title {
  font-size: 28px;
  font-weight: bold;
  padding: 0;
  margin: 0 0 20px 0;
}
.blog .entry .entry-title a {
  color: #111;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.blog .entry .entry-title a:hover {
  color: #4a30ed;
}
.blog .entry .entry-meta {
  margin-bottom: 15px;
  color: #777777;
}
.blog .entry .entry-meta ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  list-style: none;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0;
  margin: 0;
}
.blog .entry .entry-meta ul li + li {
  padding-left: 20px;
}
.blog .entry .entry-meta i {
  font-size: 16px;
  margin-right: 8px;
  line-height: 0;
}
.blog .entry .entry-meta a {
  color: #847872;
  font-size: 14px;
  display: inline-block;
  line-height: 1;
}
.blog .entry .entry-content p {
  line-height: 40px;
  /* height: 160px; */
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: 400;
}
.matchbox {
  display: flex;
}
.matchbox :first-child {
  margin-right: 10px;
  border-radius: 5px;
}

.blog .entry .entry-content .read-more {
  -moz-text-align-last: right;
  text-align-last: right;
  padding: 20px;
}

.blog .entry .entry-content .read-more a {
  display: inline-block;
  background: #6927e8;
  color: #fff;
  padding: 6px 20px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  font-size: 14px;
  border-radius: 4px;
}
.blog .entry .entry-content .read-more a:hover {
  background: #4a30ed;
}

.read-more {
  -moz-text-align-last: right;
  text-align-last: right;
  padding: 20px;
}
.read-more a {
  display: inline-block;
  background: #6927e8;
  color: #fff;
  padding: 6px 20px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  font-size: 14px;
  border-radius: 4px;
}
.read-more a:hover {
  background: #4a30ed;
}


.blog .entry .entry-content h3 {
  font-size: 22px;
  margin-top: 30px;
  font-weight: bold;
}
.blog .entry .entry-content blockquote {
  overflow: hidden;
  background-color: #fafafa;
  padding: 60px;
  position: relative;
  text-align: center;
  margin: 20px 0;
}
.blog .entry .entry-content blockquote p {
  color: #4d4643;
  line-height: 1.6;
  margin-bottom: 0;
  font-style: italic;
  font-weight: 500;
  font-size: 22px;
}
.blog .entry .entry-content blockquote::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 3px;
  background-color: #111;
  margin-top: 20px;
  margin-bottom: 20px;
}
.blog .entry .entry-footer {
  padding-top: 10px;
  border-top: 1px solid #e6e6e6;
}
.blog .entry .entry-footer i {
  color: #5e5e5e;
  display: inline;
}
.blog .entry .entry-footer a {
  color: #1e1e1e;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.blog .entry .entry-footer a:hover {
  color: #4a30ed;
}
.blog .entry .entry-footer .cats {
  list-style: none;
  display: inline;
  padding: 0 20px 0 0;
  font-size: 14px;
}
.blog .entry .entry-footer .cats li {
  display: inline-block;
}
.blog .entry .entry-footer .tags {
  list-style: none;
  display: inline;
  padding: 0;
  font-size: 14px;
}
.blog .entry .entry-footer .tags li {
  display: inline-block;
}
.blog .entry .entry-footer .tags li + li::before {
  padding-right: 6px;
  color: #6c757d;
  content: ",";
}
.blog .entry .entry-footer .share {
  font-size: 16px;
}
.blog .entry .entry-footer .share i {
  padding-left: 5px;
}
.blog .entry-single {
  margin-bottom: 30px;
}
.blog .blog-author {
  padding: 20px;
  margin-bottom: 30px;
  -webkit-box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
          box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}
.blog .blog-author img {
  width: 120px;
  margin-right: 20px;
}
.blog .blog-author h4 {
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 0px;
  padding: 0;
  color: #111;
}
.blog .blog-author .social-links {
  margin: 0 10px 10px 0;
}
.blog .blog-author .social-links a {
  color: rgba(17, 17, 17, 0.5);
  margin-right: 5px;
}
.blog .blog-author p {
  font-style: italic;
  color: #bfb9b6;
}
.blog .blog-comments {
  margin-bottom: 30px;
}
.blog .blog-comments .comments-count {
  font-weight: bold;
}
.blog .blog-comments .comment {
  margin-top: 30px;
  position: relative;
}
.blog .blog-comments .comment .comment-img {
  margin-right: 14px;
}
.blog .blog-comments .comment .comment-img img {
  width: 60px;
}
.blog .blog-comments .comment h5 {
  font-size: 16px;
  margin-bottom: 2px;
}
.blog .blog-comments .comment h5 a {
  font-weight: bold;
  color: #4d4643;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.blog .blog-comments .comment h5 a:hover {
  color: #4a30ed;
}
.blog .blog-comments .comment h5 .reply {
  padding-left: 10px;
  color: #111;
}
.blog .blog-comments .comment h5 .reply i {
  font-size: 20px;
}
.blog .blog-comments .comment time {
  display: block;
  font-size: 14px;
  color: #2b2b2b;
  margin-bottom: 5px;
}
.blog .blog-comments .comment.comment-reply {
  padding-left: 40px;
}
.blog .blog-comments .reply-form {
  margin-top: 30px;
  padding: 30px;
  -webkit-box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
          box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}
.blog .blog-comments .reply-form h4 {
  font-weight: bold;
  font-size: 22px;
}
.blog .blog-comments .reply-form p {
  font-size: 14px;
}
.blog .blog-comments .reply-form input {
  border-radius: 4px;
  padding: 10px 10px;
  font-size: 14px;
}
.blog .blog-comments .reply-form input:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
  /* border-color: #57e76f; */
}
.blog .blog-comments .reply-form textarea {
  border-radius: 4px;
  padding: 10px 10px;
  font-size: 14px;
}
.blog .blog-comments .reply-form textarea:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
  /* border-color: #57e76f; */
}
.blog .blog-comments .reply-form .form-group {
  margin-bottom: 25px;
}
.blog .blog-comments .reply-form .btn-primary {
  border-radius: 4px;
  padding: 10px 20px;
  border: 0;
  background-color: #111;
}
.blog .blog-comments .reply-form .btn-primary:hover {
  background-color: #1e1e1e;
}
.blog .blog-pagination {
  color: #444444;
}
.blog .blog-pagination ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
}
.blog .blog-pagination li {
  margin: 0 5px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.blog .blog-pagination li a {
  color: #111;
  padding: 7px 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.blog .blog-pagination li.active, .blog .blog-pagination li:hover {
  background: #4a30ed;
}
.blog .blog-pagination li.active a, .blog .blog-pagination li:hover a {
  color: #fff;
}
.blog .sidebar {
  padding: 30px;
  margin: 0 0 60px 20px;
  -webkit-box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
          box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}
.blog .sidebar .sidebar-title {
  font-size: 20px;
  font-weight: 700;
  padding: 0 0 0 0;
  margin: 0 0 15px 0;
  color: #111;
  position: relative;
}
.blog .sidebar .sidebar-item {
  margin-bottom: 30px;
}
.blog .sidebar .search-form form {
  background: #fff;
  border: 1px solid #ddd;
  padding: 3px 10px;
  position: relative;
}
.blog .sidebar .search-form form input[type=text] {
  border: 0;
  padding: 4px;
  border-radius: 4px;
  width: calc(100% - 40px);
}
.blog .sidebar .search-form form button {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border: 0;
  background: none;
  font-size: 16px;
  padding: 0 15px;
  margin: -1px;
  background: #6927e8;
  color: #fff;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  border-radius: 0 4px 4px 0;
  line-height: 0;
}
.blog .sidebar .search-form form button i {
  line-height: 0;
}
.blog .sidebar .search-form form button:hover {
  background: #4a30ed;
}
.blog .sidebar .categories ul {
  list-style: none;
  padding: 0;
}
.blog .sidebar .categories ul li + li {
  padding-top: 10px;
}
.blog .sidebar .categories ul a {
  color: #111;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.blog .sidebar .categories ul a:hover {
  color: #4a30ed;
}
.blog .sidebar .categories ul a span {
  padding-left: 5px;
  color: #b4aca8;
  font-size: 14px;
}
.blog .sidebar .recent-posts .post-item + .post-item {
  margin-top: 15px;
}
.blog .sidebar .recent-posts img {
  width: 80px;
  float: left;
}
.blog .sidebar .recent-posts h4 {
  font-size: 15px;
  margin-left: 95px;
  font-weight: bold;
}
.blog .sidebar .recent-posts h4 a {
  color: #111;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.blog .sidebar .recent-posts h4 a:hover {
  color: #4a30ed;
}
.blog .sidebar .recent-posts time {
  display: block;
  margin-left: 95px;
  font-style: italic;
  font-size: 14px;
  color: #b4aca8;
}
.blog .sidebar .tags {
  margin-bottom: -10px;
}
.blog .sidebar .tags ul {
  list-style: none;
  padding: 0;
}
.blog .sidebar .tags ul li {
  display: inline-block;
}
.blog .sidebar .tags ul a {
  color: #515151;
  font-size: 14px;
  padding: 6px 14px;
  margin: 0 6px 8px 0;
  border: 1px solid #c4c4c4;
  display: inline-block;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.blog .sidebar .tags ul a:hover {
  color: #fff;
  border: 1px solid #00a0e9;
  background: #00a0e9;
}
.blog .sidebar .tags ul a span {
  padding-left: 5px;
  color: #aaaaaa;
  font-size: 14px;
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
  /* margin-top:20px; */
  color: #fff;
  font-size: 14px;
  background: linear-gradient(350deg, #452f2f, #00a0e9) !important;
}

#footer .footer-top {
  padding: 60px 0 30px 0;
  background: linear-gradient(334deg, #9d4140, #00a0e9) !important;
  /* margin-left:7px; */
}

#footer .footer-top .footer-contact {
  margin-bottom: 30px;
}

#footer .footer-top .footer-contact h3 {
  font-size: 22px;
  margin: 0 0 10px 0;
  padding: 2px 0 2px 0;
  line-height: 1;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #fff;
}
#footer .footer-top .footer-contact p {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 0;
  font-family: "Roboto", sans-serif;
  /* letter-spacing: 1px; */
  color: #fff;
}
#footer .footer-top h4 {
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  position: relative;
  padding-bottom: 12px;
  margin-left: 6px;
}
#footer .footer-top .footer-links {
  margin-bottom: 30px;
  letter-spacing: 1px;
}
#footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
#footer .footer-top .footer-links ul i {
  padding-right: 2px;
  color: #fff;
  font-size: 18px;
  line-height: 1;
}
#footer .footer-top .footer-links ul li {
  padding: 10px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
#footer .footer-top .footer-links ul li:first-child {
  padding-top: 0;
}
#footer .footer-top .footer-links ul a {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
}
#footer .footer-top .footer-links ul a:hover {
  text-decoration: none;
  color: #ffc107;
  opacity: 0.5;
}
#footer .footer-newsletter {
  font-size: 15px;
  color: rgba(255, 255, 255, 0.7);
}
#footer .footer-newsletter h4 {
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  position: relative;
  padding-bottom: 12px;
}
#footer .footer-newsletter form {
  margin-top: 30px;
  background: #fff;
  padding: 6px 10px;
  position: relative;
  border-radius: 4px 0 0 4px;
  text-align: left;
}
#footer .footer-newsletter form input[type=email] {
  border: 0;
  padding: 4px 8px;
  width: calc(100% - 100px);
}
#footer .footer-newsletter form input[type=submit] {
  position: absolute;
  top: 0;
  right: -4px;
  bottom: 0;
  border: 0;
  background: none;
  font-size: 16px;
  padding: 0 20px;
  background: #4a30ed;
  color: #fff;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  border-radius: 0 4px 4px 0;
  -webkit-box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}
/* #footer .footer-newsletter form input[type=submit]:hover {
   background: #2ae149; 
} */
#footer .credits {
  padding-top: 5px;
  font-size: 13px;
  color: #fff;
}
#footer .social-links a {
  font-size: 18px;
  display: inline-block;
  background: rgba(255, 255, 255, 0.08);
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 4px;
  text-align: center;
  width: 36px;
  height: 36px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
#footer .social-links a:hover {
  background: #4a30ed;
  color: #fff;
  text-decoration: none;
}
/*--------------------------------------------------------------
# Blog
--------------------------------------------------------------*/
.blog {
  padding: 40px 0 20px 0;
}
.blog .entry {
  padding: 30px;
  margin-bottom: 60px;
  -webkit-box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
          box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  /* height: 437px; */
  background-color: #fff;
  /* display: flex; */
}

/* .blog .entry.ceoImg {
   background-color:#767a98;
  background: #767a98;  
  background: linear-gradient(334deg, #767a98, #c9bcc4) !important; 
  padding:0;
  background: none !important;
  box-shadow: none !important;
} */

.blog .entry img{
  width: 100%;
  height: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.blog .entry .entry-title {
  font-size: 21px;
  font-weight: bold;
  padding: 0;
  margin: 0 0 20px 0;
}
.blog .entry .entry-title a {
  color: #111;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.blog .entry .entry-title a:hover {
  color: #4a30ed;
}

.blog .entry .entry-content h3 {
  font-size: 22px;
  margin-top: 30px;
  font-weight: bold;
}
.blog .entry .entry-content blockquote {
  overflow: hidden;
  background-color: #fafafa;
  padding: 60px;
  position: relative;
  text-align: center;
  margin: 20px 0;
}
.blog .entry .entry-content blockquote p {
  color: #4d4643;
  line-height: 1.6;
  margin-bottom: 0;
  font-style: italic;
  font-weight: 500;
  font-size: 22px;
}
.blog .entry .entry-content blockquote::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 3px;
  background-color: #111;
  margin-top: 20px;
  margin-bottom: 20px;
}
/* sec-carrerCSS */
.text-un-yellow {
  background-image: -webkit-linear-gradient(left, #fff 50%, #FFDE6A 50%);
  background-repeat: repeat-x;
  background-position: -100% 84%;
  background-size: 200% 7%;
}
.text-un-yellow {
  background-image: -webkit-linear-gradient(left, #fff 50%, #FFDE6A 50%);
  background-repeat: repeat-x;
  background-position: -100% 84%;
  background-size: 200% 7%;
}
.ttl-subpage {
  font-size: 30px;
  font-weight: bold;
  color: #008135;
  text-align: center;
  margin-bottom: 20px;
}
.table-style01 {
  margin: 30px 0 40px 0;
}
.table-style01 table tr th {
  vertical-align: middle;
   background: #ebebeb; 
}
.sec-table table {
  margin: 0 auto;
}

.sec-table table tr th {
  background: #f5f2f2;
  text-align: center;
}
.sec-table table tr th, .sec-table table tr td {
  padding: 20px 10px;
  border: 2px solid #ccc;
}

.td_color {
  width: 300px;
  text-align: center;
  background: #F2F9F5 !important;
  font-weight: bold;
}

.require {
  color: #f00;
  vertical-align: top;
  font-weight: bold;
}
/* Form Page */
.form-label {
  display: inline-block;
  font-size: 16px;
  margin-bottom: 5px;
}
.form-sign {
  padding: 0 7%;
}
.form-yern {
  padding-left: 5%;
}
.form-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.form-row2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.form-secblock {
  
  position: relative;
  z-index: 1;
  padding-top: 30px;
}
.form-secblock-lg {
  z-index: 1;
  padding-top: 159px;
  padding-bottom: 99px;
}
.form-secblock .letter:before {
  left: 0;
  top: 4px;
  -webkit-transform: rotate(-0.6deg);
      -ms-transform: rotate(-0.6deg);
          transform: rotate(-0.6deg);
}
.form-secblock .letter:after {
  right: -3px;
  top: 1px;
  -webkit-transform: rotate(0.7deg);
      -ms-transform: rotate(0.7deg);
          transform: rotate(0.7deg);
}
.form-col1 {
  width: 100%;
}
.form-col2 {
  width: 48%;
}
.form-col4 {
  width: 24%;
  min-width: 250px;
  padding: 5px 0;
}
.form-col6 {
  width: 16%;
  min-width: 200px;
  padding: 5px 0;
}
.form-mb {
  margin-bottom: 30px;
}
.form-mb10 {
  margin-bottom: 10px;
}
/* letter */
.letter {
  margin-top: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%;
  background: #fff;
  padding: 30px 30px 20px;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  position: relative;
  
  line-height: 1.8;
}
.letter:before,
.letter:after {
  content: "";
  height: 97%;
  position: absolute;
  width: 100%;
  z-index: -1;
}
.letter:before {
  -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
          box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  left: -5px;
  top: 4px;
  -webkit-transform: rotate(-2.5deg);
      -ms-transform: rotate(-2.5deg);
          transform: rotate(-2.5deg);
}
.letter:after {
  background: #f6f6f6;
  -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
          box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  right: -3px;
  top: 1px;
  -webkit-transform: rotate(1.4deg);
      -ms-transform: rotate(1.4deg);
          transform: rotate(1.4deg);
}
.letter-lead-txt {
  margin-bottom: 20px;
  font-family: 'Roboto', sans-serif;
}
.letter-b-txt {
  float:right;
}
.letter-b-txt p {
  margin-bottom: 5px;
}
.c-w1170 {
  max-width: 1230px;
  padding: 0 30px;
  margin: 0 auto;
}
.inputType {
  width: 100%;
  height: 40px;
  border: 1px solid #A0A0A0;
  border-radius: 10px;
  background: #fff;
  font-size: 15px;
  outline: none;
  padding: 10px 15px;
  margin-bottom: 10px;
  line-height: 1;
}
.inputType:last-child {
  margin-bottom: 0;
}
.form-inputText ::-webkit-input-placeholder {
  visibility: visible !important;
  font-size: 14px;
  color: #A0A0A0;
  /* font-weight: bold; */
}
.form-inputText ::-moz-placeholder {
  visibility: visible !important;
  font-size: 14px;
  color: #A0A0A0;
  /* font-weight: bold; */
}
.form-inputText :-ms-input-placeholder {
  visibility: visible !important;
  font-size: 14px;
  color: #A0A0A0;
  /* font-weight: bold; */
}
.form-inputText ::-ms-input-placeholder {
  visibility: visible !important;
  font-size: 14px;
  color: #A0A0A0;
  /* font-weight: bold; */
}
.form-inputText ::placeholder {
  visibility: visible !important;
  font-size: 14px;
  color: #A0A0A0;
  /* font-weight: bold; */
}
.input-salary {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.input-salary .inputType{
  max-width: 170px;
  margin-bottom: 0;
}
/* radio custom css */
input[type="radio"]:checked,
input[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
input[type="radio"]:checked + .form-radio-text,
input[type="radio"]:not(:checked) + .form-radio-text
{
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  display: inline-block;
  line-height: 20px;
}
input[type="radio"]:checked + .form-radio-text:before,
input[type="radio"]:not(:checked) + .form-radio-text:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border: 1px solid #3281b2;
  border-radius: 100%;
  background: #fff;
}
input[type="radio"]:checked + .form-radio-text:after,
input[type="radio"]:not(:checked) + .form-radio-text:after {
  content: '';
  width: 11px;
  height: 11px;
  background: #3281b2;
  position: absolute;
  top: 4.4px;
  left: 4.5px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
input[type="radio"]:not(:checked) + .form-radio-text:after {
  opacity: 0;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
      transform: scale(0);
}
input[type="radio"]:checked + .form-radio-text:after {
  opacity: 1;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
      transform: scale(1);
}
/* radio custom css */
/* checkbox custom css */
input[type="checkbox"] {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}
.form-checkbox {
  position: relative;
  cursor: pointer;
  /* display: flex;
  align-items: center; */
  word-break: break-word;
}
.form-checkbox:before {
  content:'';
  /* -webkit-appearance: none; */
  background-color: transparent;
  border: 1px solid #3281b2;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
          box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 8px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-top: -4px;
  margin-right: 5px;
}
input[type="checkbox"]:checked + .form-checkbox:after {
  content: '';
  display: block;
  position: absolute;
  top: 1px;
  left: 7px;
  width: 5px;
  height: 14px;
  border: solid #3281b2;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
          transform: rotate(45deg);
}
/* checkbox custom css */
/* Input Type File */
.form-inputFile {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.inputFile {
  line-height: 14px;
}

input[type="file"] {
  display: block;
  width: 100%;
  /* height: 40px; */
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 10px;
  font-size: 16px;
  cursor: pointer;
}
input[type="file"]::-webkit-file-upload-button {
  padding: 10px 20px;
  border: 1px solid #ced4da;
  border-top-width: 0;
  border-bottom-width: 0;
  border-left-width: 0;
  border-radius: 0;
  background: #e9ecef;
  -webkit-transition: 1s;
  transition: 1s;
  margin-right: 20px;    
}
input[type="file"]::file-selector-button {
  padding: 10px 20px;
  border: 1px solid #ced4da;
  border-top-width: 0;
  border-bottom-width: 0;
  border-left-width: 0;
  border-radius: 0;
  background: #e9ecef;
  -webkit-transition: 1s;
  -o-transition: 1s;
  transition: 1s;
  margin-right: 20px;    
}

/* Input Type File */
.form-textarea textarea {
  width: 100%;
  height: 200px;
  border: 1px solid #A0A0A0;
  border-radius: 10px;
  padding: 15px;
}
.form-privacy {
  height: 300px;
  overflow: auto;
  border: 1px solid #A0A0A0;
  padding: 20px;
  border-radius: 4px;
  line-height: 1.8;
  margin-top: 20px;
}
.form-sign {
  padding: 0 7%;
}
.sub-ttl {
  font-size: 40px;
  color: #fff;
  font-weight: bold;
  margin-bottom: 0px;
}
.slide_right:hover {
  /* box-shadow: inset 400px 0 0 0 #383; */
  color: white;
}
#outer {
  margin-left: 424px;
  margin-top: 0px;
  padding-left: 4px;
  width: 200px;
}

.recruit-txt {
  /* line-height: 1.8; */
  text-align: justify;
  font-size: 14px;
  line-height: 15px;
}
.recruit-ttl, .pri-ttl {
  font-size: 20px;
  /* color:#008135; */
  margin-bottom: 10px;
  font-weight: bold;
}
.recruit-desp {
  color: #000;
  font-size: 13px;
}
.recruit-btnls {
  margin-bottom: 30px;
  padding-left: 0;
}
.recruit-btnbox {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.recruit-btnbox span {
  font-size: 13px;
  display: inline-block;
  /* background: #008135; */
  padding: 5px 7px;
  color: #fff;
  font-weight: bold;
  width: 100px;
}

.recruit-btnbox p {
  width: calc(94% - 80px);
  margin-left: auto;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

/* #kakuRecurit{
  padding:20px;
} */

#recuritCntFld{
  width:87%;
  margin-left:107px;
  padding:10px;
}

#recuritSE{
  padding:30px;
}

ul {
  list-style: none;
}

.private-lead-txt {
  color: black;
}

.reset_margin{
margin: 0;
}
.entry-body{  
  font-size: 16px;
    line-height: 25px;
}

.btn02{
  background: #3281b2;
  border: 0;
  padding: 10px 24px;
  color: #fff;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  border-radius: 4px;
  text-decoration: none;
}
.btn02:hover {
  background: #3281b2;
  opacity: 0.7;
  color: #fff;
}
.btn02:focus {
  border-color: #4a30ed;
}
.col-red{
  color:#f00 !important;
}
.file-para{
  margin: 0;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
/* Top page and News List Page 2 page common used
News Block 
*/
/* ニュース画面 */
.newsBlock {
  position: relative;
  z-index: 1;
}
.newsBlock ul {
  margin-bottom: 30px;
}
.newsBlock ul li {
  border-bottom: 1px solid #1f124059;
  -webkit-box-shadow: 0px 5px 4px -6px #1f124059;
          box-shadow: 0px 5px 4px -6px #1f124059;
  /* margin-top: 20px; */
}
.newsBlock ul li:first-child{
  margin-top: 0px;
}
.newsBlock ul li .newsLink {
  position: relative;
  padding: 20px 20px 10px ;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  /* font-weight: 500; */
  color: #000;
}

.newsBlock ul li .newsLink:after {
  content: "";
  position: absolute;
  top: calc(50% - 3px);
  right: 10px;
  width: 31px;
  height: 8px;
  margin-right: 15px;
  background: url(../img/common/right_arrow.png) no-repeat;
  background-size: 31px;
  -webkit-transition: transform 0.3s ease-out;
  -o-transition: transform 0.3s ease-out;
  -webkit-transition: -webkit-transform 0.3s ease-out;
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
}
.newsBlock ul li .newsLink:hover:after{
  -webkit-transform: translateX(10px);
  -ms-transform: translateX(10px);
  transform: translateX(10px);
}
.newsBlock ul li .newsDate {
  width: 150px;
  /* text-align: center; */
}
.newsBlock ul li .newsTtl {
  width: calc(100% - 150px);
  padding: 0 3% 0 10%;
  /* font-weight: 500; */
}
.newsBlock .btn-arr {
  max-width: 265px;
}
.news-section-title {
  text-align: center;
}

/* サービス画面 */
.service-container{
  width:80%;
  /* margin: 50px auto; */
  /* background-color: rgb(241 243 242); */
  background-color: rgba(248, 250, 250, 0.999);
}

.servicedv{  
  background-color:#ffffff; 
  border-radius: 10px;
  left:10px;
  right:10px;
}

.clearfix-padding{
  padding: 0;
}
/* 問合せ画面 */
.mapDATA {  
  width: 100%;
  height:73vh;
  background: #f7f3f3;
  position: relative;
  overflow: hidden;
  background-size: cover;
  background-position: center center;
  z-index: 1;
}

.contact-container {
  width: 84.5%;
  margin-left: 132px;
}



li.nav-item {
  /* border-bottom: 1px solid #c1c1c1; */
  /* width: 98%;
  height: 100%; */
  height: 50%;
  line-height: 25px;
  left:10px;
}

#listNavTitle{
  width: 9%;
}
#listNavTitle:nth-child(5) {
  width: 15%;
}

#serviceNavList li.nav-item{
  border-bottom: 1px solid #c1c1c1;
  width: 97%;
  height: 100%; 
}

li.nav-item a{
  font-weight: 400;
}

.nav {
  line-height:40px;
  display: block;
  float:left;
  padding:0px;
  font-weight: 300;
}
.service-title {
  font-size: 21px;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  padding-top: 30px;
  margin: 0 0 20px 0;
}

#company .service-title {
  padding-top: 0;
}
.service-img {
  width:100%;
  height:100%;
  /* border-style: groove;
  border-radius: 1px #6e87d6; */
  background-color: white;
  -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  pointer-events: none;
}

.nav-link {  
  color: #000 !important; 
  font-weight: 600;
}

.service-container .nav-item.active {
  color: black !important;
  width: 98%;
  height: 70%;
  -webkit-box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
          box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
}
.nav-item.active::after {
  content: " ";
  display: block;
  color:black;
  width: 80%;
  height: 2px;
  margin-left: 15px;
}

.nav-link.active {
  color: black;
  font-weight: 900;
  width: 100%;
}
.navbar-collapse {
  color: black;
}
/* Service SideBar NAV */
.navbar-list{
    width: 100%;
    /* margin: 0px 5px; */
    margin: 31px 5px;
}
@media (min-width: 992px){
.navbar-expand-lg .navbar-collapse {
    display: block !important;
    -ms-flex-preferred-size: auto;
        flex-basis: auto;
}
}

/* 会社情報画面 */
#cmpInfDiv{
  margin-left:85px;
  width:88%;
}

.company-title{
  color: #000;
  font-size: 21px;
  font-weight: bold;
  /* border-bottom: 1px solid #1f124059; */
  -webkit-box-shadow: 0px 5px 4px -6px #1f124059;
          box-shadow: 0px 5px 4px -6px #1f124059;
  padding-top: 20px;
}
.companyBlock ul li .company-Data {
  font-size: 14px;
  line-height: 30px;
  letter-spacing: 1px;
  margin-top:10px;
}

#cmpInfoUL{
  padding:30px 0;
}

section #company {
  background-color: white;
  width: 99%;
  margin: 0 auto;
  display: block;
}

.card {
  margin: 1em auto;
}

/* Company Info Timeline */

.main-timeline{ font-family: 'Poppins', sans-serif; }
.main-timeline:after{
    content: '';
    display: block;
    clear: both;
}
.main-timeline .timeline{
    width: 100%;
    padding: 20px 0 0;
    margin: 5px 0 50px 5px;
    float: right;
}

.main-timeline .timeline-content{
    color: #fff;
    background: #4a30ed00;
    text-align: center;
    padding: 0 30px 40px 75px;
    border-radius: 7px;
    box-shadow: 0 5px 15px rgba(0,0,0,0.1);
    display: block;
    position: relative;
    z-index: 1;
}
.main-timeline .timeline-content:hover{ text-decoration: none; }
.main-timeline .timeline-content:before,
.main-timeline .timeline-content:after{
    content: '';
    /* background: linear-gradient(#603472, #6c26e8); */
    background: linear-gradient(182deg, #0a9adf, #7a5766) !important;
    height: 100%;
    border-radius: 0 15px 15px 15px;
    position: absolute;
    left: 55px;
    right: 20px;
    top: -20px;
    z-index: -1;
    border-color: #fff;
    -webkit-transform: scale3d(1.01,1.01,1.01);
            transform: scale3d(1.01,1.01,1.01)
}
.main-timeline .timeline-content:before{
    background: linear-gradient(to right bottom, transparent 50%, #696179 52%) !important;
    height: 20px;
    width: 15px;
    border-radius: 0;
    left: 40px;
    top: -20px;
    right: auto;
}
.main-timeline .timeline-icon{ font-size: 50px; }
.main-timeline .title{
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    margin: 0 0 10px;
}
.main-timeline .description{
    font-size: 13px;
    letter-spacing: 1px;
    margin: 0;
}
.main-timeline .timeline-year{
    color: #c94504;
    font-size: 35px;
    font-weight: 700;
    transform: translateY(-50%) rotate(90deg);
    position: absolute;
    left: -14px;
    top: 48%;
}
.main-timeline .timeline:nth-child(even){
    margin: 0 5px 25px 0;
    float: left;
}
.main-timeline .timeline:nth-child(even) .timeline-content{ padding: 0 75px 40px 30px; }
.main-timeline .timeline:nth-child(even) .timeline-content:before{
    transform: rotateY(180deg);
    left: auto;
    right: 40px;
}
.main-timeline .timeline:nth-child(even) .timeline-content:after{
    border-radius: 15px 0 15px 15px;
    left: 20px;
    right: 55px;
}
.main-timeline .timeline:nth-child(even) .timeline-year{
    left: auto;
    right: -11px;
}
.main-timeline .timeline:nth-child(4n+2) .timeline-content:after{
    background: linear-gradient(#990a6b, #58058ecc);
}
.main-timeline .timeline:nth-child(4n+2) .timeline-content::before{
    background: linear-gradient(to right bottom, transparent 50%, #8A1E65 52%);
}
.main-timeline .timeline:nth-child(4n+2) .timeline-year{ color: #8A1E65; }
@media screen and (max-width:767px){
    .main-timeline .timeline,
    .main-timeline .timeline:nth-child(even){
        width: 100%;
        /* margin: 0 0 35px 0; */
        margin: 0 auto 20px;
        display: block;
        box-shadow: 2px 4px 12px rgba(0,0,0,.08);
    }
    .serviceEntry  {
      margin-left: 0!important;
    }
    .servicePStyle {
      margin: 0 auto 20px!important;
    }
    #appSvBody {
      margin-left:23px!important;
    }
    .kigyorinenPStyle {
      margin: 0 auto 20px!important;
      font-family: 'Roboto', sans-serif;
    }
    #appRinenBody {
      margin-left:23px!important;
      font-family: 'Roboto', sans-serif;
    }
    .kigyorinenDv-cover {
      background: #fff;
      display: flex;
    }
}

.kigyorinenDv-cover {
  padding: 30px;
  background: #fff;
  display: flex;
  font-family: 'Roboto', sans-serif;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.12) !important;
  padding: 0px 0px 0px 30px;
}
/* 社長メッセージ */
.ceoMsgDv-cover {
  padding: 30px;
  background: #fff;
  display: flex;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.12) !important;
  padding: 0px 0px 0px 30px;
}

.ceoMsg{
  padding: 50px 52px 0 20px;
  font-family: 'Roboto','Noto Sans JP', sans-serif;
  vertical-align: middle;
  line-height:2;
  font-size: 20px;
  word-break: keep-all;
}

.ceoMsgImage{
  width: 40%;
}

.ceoMsgImage #ceoImg{
  max-width: 100%;
  pointer-events: none;
}

.ceo-message-box{
  width: 82%;
  margin-left: 75px;
}
.ceo-img {
  margin-left: 210px;
  max-width: 100%;
  height: 500px;
}

.ceoName {
  font-size: 12px;
  text-align: right;
  font-family: 'Roboto', sans-serif;
  /* margin-top: 40px; */
}

.ceoName span {
  font-size: 20px;
  font-weight: bold;
  margin-left: 10px;
  font-family: 'Roboto', sans-serif;
}

.ceoMsgDate {
  font-size: 12px;
  text-align: right;
  margin-top: 40px;
  font-family: 'Roboto', sans-serif;
}

.ceoMsgDate span {
  font-size: 16px;
  /* font-weight: bold; */
  margin-right: 5px;
  font-family: 'Roboto', sans-serif;
}

.flat-jp{
  width: 30px;
  height: 20px;
  border:1px solid;
  font-weight: bold;
}

.flat-eng{
  width: 28px;
  font-weight: 700;
}
.w3rcontainer{
  border: 1px solid 
#cccfdb;
  border-radius: 2px;
} 
.active {
 display: inline-block;
 color:#3D68FF;
}

.navbar #nav > .active > a {
  color: black;
}

#navbarNavAltMarkup{
  height: 750px;
  border-radius: 6px;
}

/* 採用メッセージ */
.recruit-data{
  margin-top: 98px;
  margin-left: 25px;
}
label.mt-20 {
  margin-top: 20px !important;
}
.recruit-paragraph{
  font-weight:bold;
}
.recruit-message{
  width: 116%;
  font-size: 18px;
}
/* フッター */
.footer-contact .info i {
  font-size: 20px;
  color: #fff;
  float: left;
  width: 44px;
  height: 44px;
  border: 3px solid #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 50px;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  margin-left: 20px;
  -webkit-text-stroke: 1px;
}
  
.ft-contact {
  display: flex;
  align-items: center;
  color: #fff;
}

.footer-contact .info:hover i {
  background: #3281b2;
  opacity: 0.5;
  color: #fff;
}
.info .footer{
   -webkit-box-align: center;
       -ms-flex-align: center;
           align-items: center;
}  
.ftCopyRight{
  /* text-align: -webkit-center; */
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  font-family: 'Roboto', sans-serif;  
  letter-spacing: 1px;
  padding:10px 0 0 0;
}

.under_bar:after{
  margin-left: 35px;
  content: '';
  position: absolute;
  width: 50%;
  -webkit-transform: scaleX(0);
      -ms-transform: scaleX(0);
          transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  -webkit-transform-origin: bottom right;
      -ms-transform-origin: bottom right;
          transform-origin: bottom right;
}

/* Service Paragraph Style */
.servicePStyle{
  /* word-break: keep-all;
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  line-height: 24px;
  letter-spacing: 1px; */
  margin:20px 0 auto;  
  /* word-break: keep-all; */
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  line-height: 24px;
  letter-spacing: 1px;
  word-wrap: break-word;
  padding-right: 20px;
}

#appSvBody{
  margin: 5px 0 0 23px;
}

#btnToggleStyle{
  font-size: 1.1rem;
  margin-top:2.5px;
}

.ceoMessageStyle {
  word-break:initial;
  font-size: 1px;
  font-family: 'Roboto', sans-serif;
  line-height: 2;
  letter-spacing: 1px;
}

.ceoMessage{
  word-break:initial;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  line-height: 2;
  letter-spacing: 1px;
}

#ceoMsgPstyle{
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  word-break:initial;
  line-height: 2.7;
  letter-spacing: 1px;
}

 .navbar-light .navbar-toggler{
    color: rgba(0,0,0,.55);
    border-color: #cfcfcf;
    margin-left: 16px;
    margin-bottom: 2px;
    font-size: 1.5rem;
}

.navbar-light .navbar-toggler:focus{
  -webkit-box-shadow: 0 0 0 -0.75rem !important;
          box-shadow: 0 0 0 -0.75rem !important;
}

.navbar-expand-lg .navbar-toggler{
  color: rgba(0,0,0,.55);
  border-style:groove;
  border-color: #cfcfcf;
  margin-left: 16px;
  margin-bottom: 2px;
  font-size: 1.5rem;
}

.navbar-expand-lg .navbar-toggler:focus{
  -webkit-box-shadow: 0 0 0 -0.75rem !important;
          box-shadow: 0 0 0 -0.75rem !important;
}

.navbar-expand-lg .navbar-toggler-icon{
background-size: 63%;
}

.main-title{
  font-family: "Roboto", sans-serif;
  letter-spacing: 1px;
}

.textAnimation, .textAnimation span {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}

.textAnimation span { display: inline-block }

.textDecorate span { -webkit-animation-name: textDecorate; animation-name: textDecorate }
@-webkit-keyframes textDecorate {
  from {
      -webkit-transform: perspective(600px) translate3d(0, -60px, -2000px) rotateY(75deg);
              transform: perspective(600px) translate3d(0, -60px, -2000px) rotateY(75deg);
      opacity: 0
  }
  5% { -webkit-transform: perspective(600px) translate3d(0, -60px, -1500px) rotateY(75deg); transform: perspective(600px) translate3d(0, -60px, -1500px) rotateY(75deg) }
}
@keyframes textDecorate {
  from {
      -webkit-transform: perspective(600px) translate3d(0, -60px, -2000px) rotateY(75deg);
              transform: perspective(600px) translate3d(0, -60px, -2000px) rotateY(75deg);
      opacity: 0
  }
  5% { -webkit-transform: perspective(600px) translate3d(0, -60px, -1500px) rotateY(75deg); transform: perspective(600px) translate3d(0, -60px, -1500px) rotateY(75deg) }
}

.footerStyle{
  margin-left: 1px;
}

.footerCmpAddressStyle{
  margin-left: -6px;
}


/* Email Send Loading Animation */
#loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(241, 243, 242, 0.3);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  z-index: 9999;
}

@keyframes loading-text-opacity {
  0% {
    opacity: 0
  }

  20% {
    opacity: 0
  }

  50% {
    opacity: 1
  }

  100% {
    opacity: 0
  }
}

@-webkit-keyframes loading-text-opacity {
  0% {
    opacity: 0
  }

  20% {
    opacity: 0
  }

  50% {
    opacity: 1
  }

  100% {
    opacity: 0
  }
}

.loading-container,
.loading {
  height: 100px;
  position: relative;
  width: 100px;
  border-radius: 100%;
}

#loading-text {
  -webkit-animation: loading-text-opacity 2s linear 0s infinite normal;
  animation: loading-text-opacity 2s linear 0s infinite normal;
  color: #2f6d70;
  font-family: "Helvetica Neue, " Helvetica", " "arial";
  font-size: 10px;
  font-weight: bold;
  margin-top: 30px;
  opacity: 0;
  position: absolute;
  text-align: left;
  text-transform: uppercase;
  top: 0;
  left: -23px;
  width: 250px;
  font-size: 30px;
}

.i-mail:after,
.i-mail .mail-anim:after {
  content: "";
  position: absolute;
  bottom: 5px;
  left: 5px;
  width: 15px;
  height: 4px;
  border-bottom: 2px solid black;
  border-top: 2px solid black;
}

.i-mail:before,
.i-mail .mail-anim:before {
  content: "";
  position: absolute;
  top: 5px;
  right: 5px;
  width: 7px;
  height: 6px;
  background: black;
}

.i-success:after,
.i-success .success-anim:after {
  content: "";
  position: absolute;
  bottom: 5px;
  left: 5px;
  width: 15px;
  height: 4px;
  border-bottom: 2px solid #2f6d70;
  border-top: 2px solid #2f6d70;
}

.i-success:before,
.i-success .success-anim:before {
  content: "";
  position: absolute;
  top: 5px;
  right: 5px;
  width: 7px;
  height: 6px;
  background: #2f6d70;
}

.animation {
  width: 540px;
  height: 34px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.i-mail,
.i-mail .mail-anim {
  width: 40px;
  height: 30px;
  border-radius: 5% 5%;
  border: 2px solid black;
  position: relative;
  -webkit-animation: transformS 0.3s linear;
          animation: transformS 0.3s linear;
}

@-webkit-keyframes transformS {
  50% {
    -webkit-transform: scale(0.5, 0.5);
            transform: scale(0.5, 0.5);
    opacity: 0.5;
  }
}

@keyframes transformS {
  50% {
    -webkit-transform: scale(0.5, 0.5);
            transform: scale(0.5, 0.5);
    opacity: 0.5;
  }
}

.i-mail .mail-anim {
  margin: -2px 0 0 -2px;
  -webkit-animation: moveL 0.8s infinite;
          animation: moveL 0.8s infinite;
}

@-webkit-keyframes moveL {
  100% {
    -webkit-transform: translateX(220px) rotateY(90deg);
            transform: translateX(220px) rotateY(90deg);
  }
}

@keyframes moveL {
  100% {
    -webkit-transform: translateX(220px) rotateY(90deg);
            transform: translateX(220px) rotateY(90deg);
  }
}

.line {
  padding: 1px 210px;
  background-image: -webkit-gradient(linear, left top, right top, color-stop(30%, #000), color-stop(0%, rgba(255, 255, 255, 0)));
  background-image: -o-linear-gradient(left, #000 30%, rgba(255, 255, 255, 0) 0%);
  background-image: linear-gradient(to right, #000 30%, rgba(255, 255, 255, 0) 0%);
  background-position: top;
  background-size: 15px 2px;
  background-repeat: repeat-x;
}

.i-success,
.i-success .success-anim {
  width: 40px;
  height: 30px;
  border-radius: 5% 5%;
  border: 2px solid #2f6d70;
  position: relative;
  -webkit-animation: transformB 0.9s 1.6s infinite forwards;
          animation: transformB 0.9s 1.6s infinite forwards;
}

.i-success:after,
.i-success .success-anim:after {
  -webkit-animation: transformBA 0.9s 1.8s infinite forwards;
          animation: transformBA 0.9s 1.8s infinite forwards;
}

@-webkit-keyframes transformB {
  50% {
    -webkit-transform: scale(1.5, 1.5);
            transform: scale(1.5, 1.5);
    background: #fff;
  }

  100% {
    background: #fff;
  }
}

@keyframes transformB {
  50% {
    -webkit-transform: scale(1.5, 1.5);
            transform: scale(1.5, 1.5);
    background: #fff;
  }

  100% {
    background: #fff;
  }
}

@-webkit-keyframes transformBA {
  100% {
    border-bottom: 2px solid #fff;
    border-left: 2px solid #fff;
  }
}

@keyframes transformBA {
  100% {
    border-bottom: 2px solid #fff;
    border-left: 2px solid #fff;
  }
}

.i-success .success-anim {
  margin: -2px 0 0 -2px;
  -webkit-animation: moveR 0.9s 1.2s infinite;
          animation: moveR 0.9s 1.2s infinite;
}

@-webkit-keyframes moveR {
  0% {
    -webkit-transform: translateX(-220px) rotateY(90deg);
            transform: translateX(-220px) rotateY(90deg);
  }

  50% {
    -webkit-transform: translateX(0) rotateY(0);
            transform: translateX(0) rotateY(0);
  }
}

@keyframes moveR {
  0% {
    -webkit-transform: translateX(-220px) rotateY(90deg);
            transform: translateX(-220px) rotateY(90deg);
  }

  50% {
    -webkit-transform: translateX(0) rotateY(0);
            transform: translateX(0) rotateY(0);
  }
}

.message {
  text-align: center;
  margin-top: 10px;
  font-family: Roboto, sans-serif;
}

/* スクリーンサイズがDesktop(2560x1440)の場合に適用 */
@media screen and (max-width: 2560px) {

    #listNavTitle {
      width: 9%;
  }

    #cmpInfDiv {
      margin-left: 85px;
      width: 87%;
  }

  .serviceEntry {
    width: 97.5%;
    margin-left: 30px;
}
    #recuritCntFld {
      width: 87%;
      margin-left: 103px;
      padding: 10px;
  }
    .contact-container {
      width: 85%;
      margin-left: 123px;
  }
}

/* スクリーンサイズがDesktop(1440x900)の場合に適用 */
@media screen and (max-width: 1440px) {

    #listNavTitle {
      width: 10%;
  }
    #cmpInfDiv {
      margin-left: 20px;
      width: 90%;
  }

    .serviceEntry {
      width: 97.5%;
      margin-left: 30px;
  }
    #recuritCntFld {
      width: 93%;
      margin-left: 55px;
      padding: 10px;
  }
    .contact-container {
      width: 92%;
      margin-left: 69px;
  }
}

/* スクリーンサイズがDesktop(1024x768)の場合に適用 */
@media screen and (max-width: 1024px) {

  /* .blog .entry {
    height: 344px;
    margin: -30px -30px 20px -30px;
    overflow: hidden;
} */

    #listNavTitle {
      width: 11%;
  }

  /* Banner Style */
    #glaxy-inner-other {
      left: 128px !important;
  }

    #cmpInfDiv {
      margin-left: 85px;
      width: 90%;
  }

    .serviceEntry {
      width: 97.5%;
      margin-left: 30px;
  }
    #recuritCntFld {
      width: 95%;
      margin-left: 38px;
      padding: 10px;
    }
    .contact-container {
      width: 92%;
      margin-left: 69px;
  }
}

/* スクリーンサイズが1000px以下の場合に適用 */
@media screen and (max-width: 1000px) {

  .blog .entry {
    /* max-height: 344px;
    margin: -30px -30px 20px -30px; */
    margin: 0px 0px 30px 0px;
    padding-bottom: 30px;
    height: auto!important;
    overflow: hidden;
}

.matchbox {
  display: block;
}
.matchbox:first-child {
  margin-right: 0;
}

  #recuritCntFld{
    width:100%;
    margin-left:2px;
    padding:10px;
  }

  #cmpInfDiv{
    margin-left:9px;
  }

  .newsBlock ul li .newsLink{
    position:relative;
    padding: 20px 20px 10px;
    display:-ms-grid;
    display:grid;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    white-space: nowrap;
    color:#000;
  }

  .newsBlock ul li .newsTtl{
    padding: 0;
  }

  .img-fluid{
    max-width: 100%;
    height: 425px;
  }

  .serviceEntry {
    width: 92%;
    margin-left: 20px;
  }
  .contact-container{
    width: 84.5%;
    margin-left: 40px;
  }


  .custom-input::placeholder {
    color: red;
    position: relative;
  }
  
  .custom-input::placeholder::after {
    content: '*';
    position: absolute;
    top: 0;
    right: -6px;
    color: red;
  }

  .back-to-top{
    bottom:55px;
  }
  
  /* .entry{
    width:100%;
  } */
} 

/* スクリーンサイズがDesktop(768)の場合に適用 */
@media screen and (max-width: 768px) {
    /* .blog .entry {
      max-height: 344px;
      margin: -30px -30px 20px -30px;
      overflow: hidden;
  } */

    #listNavTitle {
      width: 10% !important;
  }

    #cmpInfDiv {
      margin-left: 14px;
      width: 90%;
  }

    .serviceEntry {
      width: 100%;
      margin-left: 30px;
  }
    #recuritCntFld {
      width: 95%;
      margin-left: 12px;
      padding: 10px;
    }
    .contact-container {
      width: 92%;
      margin-left: 10px;
  }
  .contact .php-email-form {
    margin-top: 30px!important;
  }
  .contact iframe {
    min-height: 35vh!important;
  }
}

/* 沿革 */

.yange-block ul li .yangeDate {
  width: 146px;
  display: inline-block;
  border: 1px solid #1f124059;
  -webkit-box-shadow: 0px 5px 4px -6px #1f124059;
  box-shadow: 0px 5px 4px -6px #1f124059;
  padding: 16px 20px 10px;
  background: #3281b2;
  border-radius: 5px;
  color: #fff;
  margin-right: 5px;
  text-align: center;
}

.yange-block ul li .yangeTtl {
  width: calc(100% - 150px);
  padding: 20px 3% 10px 5%;
  border: 1px solid #dcd7e6f7;
  -webkit-box-shadow: 0px 5px 4px -6px #1f124059;
  box-shadow: 0px 5px 4px -6px #1f124059;
  border-radius: 5px;
  display: inline-block;
  /* display: inline-flex; */
  /* background: linear-gradient(182deg, #397a97db, #df151340) !important; */
  background: linear-gradient(182deg,#397a97db,#d32b3b47) !important;
  font-weight: 400;
  font-weight: 400;
}
.yange-block ul li {
  padding: 20px 0px 10px;
}

/* .yange-section {
  padding-bottom: 40px;
} */

#company ul {
  padding-left: 0!important;
}


.yange-block {
  padding-bottom: 50px;
}

#footer .footer-top .footer-contact p a {
  color: #fff;
}

.yange-block ul li {
  padding: 20px 0px 10px;
  display: flex;
  align-items: center;
}

/* 情報セキュリティ画面 */
.security-box {
  padding: 30px;
  margin-bottom: 60px;
  -webkit-box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  /* height: 437px; */
  background-color: #fff;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.12);
  display: flex;
  line-height: 2em;
}
.security-head-ttl {
  width: 100%;
  font-size: 24px;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  margin-bottom: 10px;
  color: #000;
  /* color: #008135; */
}
.security-content {
  /* width: 49.5%; */
  padding: 30px 50px;
}
.security-head-txt {
  font-size: 14px;
  font-family: 'Roboto','Noto Sans JP', sans-serif;
  line-height: 30px;
  letter-spacing: 1px;
  word-wrap: break-word;
  margin-bottom: 30px;
  color: #212529;
  min-height: 210px;
}
.security-head-linkTxt {
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    line-height: 24px;
    letter-spacing: 1px;
    word-wrap: break-word;
    position: relative;
    display: inline-block;
    font-family: 'Roboto';
    border: solid 1px #fff;
    background: #3281b2;
    font-weight: bold;
    font-size: 14px;
    padding: 9px 15px;
    width: 200px;
    overflow: hidden;
    z-index: 0;
    margin-top: auto;
    color: #fff;
}
.security-head-linkTxt:after {
  content: "";
  display: inline-block;
  border-color: #fff;
  border-style: solid;
  border-width: 0 1px 1px 0;
  width: 9px;
  height: 9px;
  vertical-align: middle;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  position: absolute;
  top: 0;
  right: 15px;
  bottom: 0;
  margin: auto;
}
.ttl {
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}
.privacy-bg {
  background-color: #f7f7f7;
}
.privacy section {
  padding: 80px 0;
}
.private02-lead-txt {
  text-align: center;
}
.sec-private03 {
  background-color: #fff;
}
.pri-num {
  padding-left: 30px;
  margin-bottom: 10px;
}
.pri-num span {
  text-indent: -25px;
  display: inline-block;
  font-weight: bold;
}
.sec-private04 {
  background-color: #f7f7f7;
  line-height: 30px;
}
.dis-inline {
  white-space: nowrap;
  display: inline !important;
  pointer-events: none;
}
.sec-private05 {
  background-color: #fff;
}
.letter-lead-txt {
  margin-bottom: 20px;
}
.alpha-ls {
  margin-left: 25px;
}
.alpha-ls span {
  margin-left: -25px;
  white-space: nowrap;
  word-break: keep-all;
}
#security ,.privacy {
  font-family: 'Roboto','Noto Sans JP', sans-serif;
}
.privacy .section-title {
  padding-top: 0px!important;
}
.privacy .letter {
  line-height: 57px;
}
#securityInfo .security-box {
  line-height: 30px;
}
.ml-30 {
  margin-left: 30px;
}
.pri-ttl {
  position: relative;
  padding: 20px;
  padding-left: 48px!important;
}

.pri-ttl:before {
  /* content: "【I】"; */
  position: absolute;
  top: 17px;
  left: 0px;
  /* background-color: #e74c3c; */
  color: #000;
  padding: 5px;
  font-size: 20px;
}
.pri-ttl1:before {
  content: "【I】";
}
.pri-ttl2,.pri-ttl3 {
  padding-left: 56px!important;
}
.pri-ttl2:before {
  content: "【Ⅱ】";
}
.pri-ttl3:before {
  content: "【III】";
}
/* add 20240322 */
/* labour */
.labour-section {
  margin-bottom: 50px;
}
.labour-info {
  font-size: 16px;
  font-family: 'Roboto','Noto Sans JP', sans-serif;
  line-height: 2;
  letter-spacing: 1px;
}
.labour-info span {
  display: block;
}
.labour-list {
  list-style-type: decimal;
}
.labour-list li {
  margin-left: 20px;
}
.labour-tbl, tr, td {
  border: 1px solid #000;
}
.labour-tbl {
  width: 100%;
  max-width: 980px;
  margin: 0 auto 50px;
}
.labour-tbl td {
  padding: 5px 10px;
}
.labour-tbl td:first-child {
  text-align: center;
  font-size: 16px;
  font-family: 'Roboto','Noto Sans JP', sans-serif;
}
.labour-sublst {
  list-style-type: disc;
  margin-left: 20px;
}
.labour-spantxt {
  display: block;
}

@media screen and (max-width: 768px) {
  .security-content {
    padding: 5px ;
    margin-bottom: 30px;
  }
  .security-head-txt {
    min-height: unset;
  }
  ul.list.alpha-list {
    padding-left: 0;
  }

  #newCompanyInfo {
    padding: 45px 0 0 0 !important;
    text-align: center;
  }
}
/* .security-content:first-child .security-head-linkTxt {
  margin-top: 50px;
} */
