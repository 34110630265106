/* src/App.css */
/* src/index.css or src/App.css */
@import '~bootstrap-icons/font/bootstrap-icons.css';
.rt-container {
  position: relative;
  padding-top: 1000px;
}

video {
  width: 100%;
  height: auto;
}

.overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.btn {
  background-color: transparent;
  border: none;
  color: white;
}